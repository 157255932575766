@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --black-gradient: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.46%,
    #11101d 91.61%
  );
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

* {
  scroll-behavior: smooth;
}
.text-gradient {
  background: radial-gradient(
    64.18% 64.18% at 71.16% 35.69%,
    #fffde7 0.89%,
    #fff9c4 17.23%,
    #fff59d 42.04%,
    #fff176 55.12%,
    #ffee58 71.54%,
    #fdd835 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.bg-blue-gradient {
  background: linear-gradient(
    157.81deg,
    #fffde7 -43.27%,
    #fff9c4 -21.24%,
    #fff59d 12.19%,
    #fff176 29.82%,
    #ffee58 51.94%,
    #fdd835 90.29%
  );
}
.bg-black-gradient {
  background: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

body {
  font-family: 'Poppins', sans-serif;
  /* Ensure all properties are properly closed */
  color: #00040f;
}






.bg-gray-gradient {
  background: linear-gradient(
    153.47deg,
    var(--primary-grey) 0%,
    var(--secondary-grey) 100%
  );
}

.bg-discount-gradient {
  background: linear-gradient(125.17deg, var(--secondary-grey) 0%, var(--primary-grey) 100%);
}

.blue__gradient {
  background: linear-gradient(180deg, rgba(255, 215, 0, 0.1) 0%, var(--primary-yellow) 100%);
  filter: blur(123px);
}

.pink__gradient {
  background: linear-gradient(90deg, var(--secondary-yellow) 0%, var(--primary-yellow) 100%);
  filter: blur(900px);
}

.white__gradient {
  background: rgba(255, 255, 255, 0.6);
  filter: blur(750px);
}

html {
  scroll-behavior: smooth;
}

.bg-gray-gradient {
  background: linear-gradient(
    153.47deg,
    rgba(255, 255, 255, 0) -341.94%,
    #14101d 95.11%
  );
}

.bg-discount-gradient {
  background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
}

.box-shadow {
  box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

.sidebar {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.feature-card:hover {
  background: var(--black-gradient);
  box-shadow: var(--card-shadow);
}

.feedback-container .feedback-card:last-child {
  margin-right: 0px;
}

.feedback-card {
  background: transparent;
}

.feedback-card:hover {
  background: var(--black-gradient);
}

:root {
  --scroll-speed: 60s;
  --scroll-width: 200%;
}

@media (max-width: 768px) {
  :root {
    --scroll-speed: 30s;
    --scroll-width: 400%;
  }
}

.scroll-container {
  width: 100%;
  overflow: hidden;
}

.scroll-content {
  display: flex;
  width: max-content; /* Allows content to determine width */
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-scroll {
  animation: scroll var(--scroll-speed) linear infinite;
}

.pause-animation {
  animation-play-state: paused;
}


